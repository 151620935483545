<template>
  <div class="content md flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{ pagination.total }}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入志工姓名查询" allow-clear class="input-box"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.id_card" placeholder="输入志工身份证号查询" allow-clear class="input-box"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.tel" placeholder="输入志工手机号码查询" allow-clear class="input-box"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker
            v-model="form.register_time"
            :placeholder="['注册开始时间', '注册结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList"
            class="range-picker"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择注册小组筛选"
            show-search
            option-filter-prop="children"
            @change="updateList"
            :options="groupOptions"
            v-model="form.volunteer_group_id"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="注册小组志工状态"
            @change="updateList"
            :options="volunStatusOptions"
            v-model="form.register_status"
            allowClear
          ></a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择专业组筛选"
            show-search
            option-filter-prop="children"
            @change="updateList"
            :options="professionalGroupOptions"
            v-model="form.professional_group"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="专业组志工状态"
            @change="updateList"
            :options="volunStatusOptions"
            v-model="form.professional_group_status"
            allowClear
          ></a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
        <a-button @click="downloadTemplate">下载模板</a-button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
        <upload-file uploadText="批量导入" type="primary" url="/admin/volunteer/import" @change="updateList"></upload-file>
        <a-button type="primary" @click="addVolunteer">添加志工</a-button>
      </a-space>
    </div>
    <div class="query-switch">
      <div class="switch-item">
        <a-switch v-model="form.interview" @change="updateList" />
        <span class="switch-text">符合面试条件</span>
      </div>
      <div class="switch-item">
        <a-switch v-model="form.straight" @change="updateList" />
        <span class="switch-text">符合转正条件</span>
      </div>
      <div class="switch-item">
        <a-switch v-model="form.seventy" @change="updateList" />
        <span class="switch-text">70周岁以上志工</span>
      </div>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h, x: 1500 }"
        @change="handleTableChange"
      >
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="showDetail(record)" v-action:query>查看</a>
            <a class="txt-btn" @click.stop="editRow(record)" v-action:update>编辑</a>
            <a class="txt-btn" @click.stop="changeStatus(record, 1)" v-if="record.status == 2">启用</a>
            <a class="txt-btn" @click.stop="changeStatus(record, 2)" v-if="record.status == 1">禁用</a>
            <a class="txt-btn danger" @click.stop="delRow(record)" v-action:delete v-if="record.status == 2">删除</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  dataConvertOptions,
  stringConvertTimestamp,
  timestampConvertString,
  getKeyTitle,
  getQuery,
  getTempleId,
  downLoadBlobFile,
  downLoadFile,
} from "../../../common/js/tool";
import {
  // registerCatOption,
  volunStatusOptions,
  accountStatusOptions,
  volunteerDetailTempleUrl,
} from "../../../common/hr/volunteer";
import { getTemple } from "../../../common/js/storage";

export default {
  name: "MemberList",
  props: {
    type: String, // claim待认领列表 claimed我的认领 open开牌列表 不传显示所有登记列表
  },
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      h: 500,
      searchKeyType: {
        name: 2,
        id_card: 2,
        tel: 2,
        register_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&register_start_time=${stringConvertTimestamp(value[0])}&register_end_time=${stringConvertTimestamp(
              value[1]
            )}`;
          }
          return res;
        },
        volunteer_group_id: 2,
        register_status: 2,
        professional_group: 2,
        professional_group_status: 2,
        interview: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        },
        straight: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        },
        seventy: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=1`;
          }
          return res;
        },
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      groupOptions: [],
      professionalGroupOptions: [],
      volunStatusOptions,
      exporting: false,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    columns() {
      let columns = [
        { title: "志工姓名", dataIndex: "name", width: 140 },
        { title: "身份证号", dataIndex: "id_card", width: 180 },
        { title: "手机号码", dataIndex: "tel", width: 120 },
        {
          title: "注册时间",
          dataIndex: "register_time",
          customRender: (text) => {
            return <span>{timestampConvertString(text, "yyyy-MM-dd")}</span>;
          },
          width: 150
        },
        {
          title: "注册小组",
          customRender: (text) => {
            return <span>{text.volunteerGroup?.name}</span>;
          },
          width: 140
        },
        {
          title: "注册小组志工状态",
          dataIndex: "register_status",
          customRender: (text) => {
            return <span>{getKeyTitle(volunStatusOptions, text, "value", "label") || "-"}</span>;
          },
          width: 150
        },
        {
          title: "服务时长（h）",
          customRender: (text) => {
            return <span>{text.duration?.service_duration || 0}</span>;
          },
          width: 130
        },
        {
          title: "活动时长（h）",
          customRender: (text) => {
            return <span>{text.duration?.activity_duration || 0}</span>;
          },
          width: 130
        },
        {
          title: "培训时长（h）",
          customRender: (text) => {
            return <span>{text.duration?.train_duration || 0}</span>;
          },
          width: 130
        },
        {
          title: "最后一次打卡时间",
          customRender: (text) => {
            return <span>{timestampConvertString(text.lastTime) || "-"}</span>;
          },
          width: 170
        },
        {
          title: "账号状态",
          dataIndex: "status",
          customRender: (text) => {
            return <span>{getKeyTitle(accountStatusOptions, text, "value", "label")}</span>;
          },
          width: 120
        },
        { title: "操作", key: "action", fixed: "right", scopedSlots: { customRender: "action" }, width: 140 },
      ];
      let index = columns.findIndex((item) => item.dataIndex == "register_status");

      let professionalGroupItem = {
        title: "专业组",
        customRender: (text) => {
          return <span>{text.professionalGroup[0]?.volunteerGroup?.name || "-"}</span>;
        },
        width: 140
      };
      if (this.form.professional_group) {
        professionalGroupItem.customRender = (text) => {
          let professionalGroup = text.professionalGroup;
          let target = text.professionalGroup?.find((item) => item.volunteer_group == this.form.professional_group);
          if (target) {
            return <span>{target?.volunteerGroup?.name}</span>;
          } else {
            return <span>{professionalGroup[0]?.volunteerGroup?.name || "-"}</span>;
          }
        };
      }
      columns.splice(index + 1, 0, professionalGroupItem);

      let professionalGroupStatusItem = {
        title: "专业组志工状态",
        customRender: (text) => {
          let professionalGroup = text.professionalGroup;
          return (
            <span>
              {getKeyTitle(volunStatusOptions, professionalGroup[0]?.volunteer_status, "value", "label") || "-"}
            </span>
          );
        },
        width: 140
      };
      if (this.form.professional_group_status) {
        professionalGroupItem.customRender = (text) => {
          let professionalGroup = text.professionalGroup;
          let target = text.professionalGroup?.find(
            (item) => item.volunteer_status == this.form.professional_group_status
          );
          if (target) {
            return <span>{getKeyTitle(volunStatusOptions, target?.volunteer_status, "value", "label") || "-"}</span>;
          } else {
            return (
              <span>
                {getKeyTitle(volunStatusOptions, professionalGroup[0]?.volunteer_status, "value", "label") || "-"}
              </span>
            );
          }
        };
      }
      columns.splice(index + 2, 0, professionalGroupStatusItem);

      return columns;
    },
  },
  created() {
    this.getGroup();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
        this.professionalGroupOptions = this.groupOptions.filter((item) => item.classify == 2);
      });
    },
    addVolunteer() {
      this.$router.push({ name: "MemberAdd" });
    },
    showDetail(record) {
      this.$router.push({
        name: "MemberDetail",
        params: { id: record.id },
      });
    },
    editRow(record) {
      this.$router.push({
        name: "MemberDetail",
        params: { id: record.id },
        query: { edit: 1 },
      });
    },
    changeStatus(record, status) {
      let target = accountStatusOptions.find((item) => item.value == status);
      this.$confirm({
        title: "提示",
        content: `确定此${target.label}此志工账号吗？`,
        onOk: () => {
          this.$axios({
            url: `/admin/volunteer/${record.id}`,
            method: "PATCH",
            data: {
              status,
            },
          }).then(() => {
            this.$message.success(`${target.label}成功`);
            record.status = status;
          });
        },
      });
    },
    delRow(record) {
      this.$confirm({
        title: "提示",
        content: `确定删除此志工吗？`,
        onOk: () => {
          this.$axios({
            url: `/admin/volunteer/${record.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    dealList(list) {
      return list;
    },
    getList() {
      this.loading = true;
      let url = `/admin/volunteer?sort=-id&expand=duration,lastTime,professionalGroup`;
      let temple = getTemple();
      if (temple) {
        url += `&filter[volunteer.temple_id]=${temple}`;
      }
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += getQuery.call(this, this.searchKeyType);
      this.$axios({
        url,
        method: "GET",
        noTempleFilter: true,
      })
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      // let searchKeyType = {};
      let url = "/admin/volunteer/export?";
      url += getQuery.call(this, this.searchKeyType);
      url += getTempleId();
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "志工数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    downloadTemplate() {
      downLoadFile(volunteerDetailTempleUrl, "志工模板");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  min-width: 0;
}
.range-picker {
  text-align: left;
}
.query-switch {
  display: flex;
  padding: 12px;
}
.switch-item {
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 24px;
  }
  .switch-text {
    margin-left: 12px;
  }
}
.input-box {
  width: 180px;
}
</style>
